import React, { useContext } from 'react';
import { DaylightContext } from '../../../contexts/daylightContext';

export default function Logo() {
	const { daylight } = useContext(DaylightContext);
	const bg = daylight ? '#4a0f59' : '#fff';
	const m = daylight ? '#fff' : '#4a0f59';
	return (
		<svg
			style={{
				maxWidth: '2.8rem',
				width: '2.8rem',
				height: '2.8rem',
				zIndex: 1,
			}}
			width="90.1"
			viewBox="0 0 23.839 23.839"
			role="img"
			aria-labelledby="logo-title logo-desc"
		>
			<title id="logo-title">Marie logo</title>
			<desc id="logo-desc">
				{daylight
					? 'White pixelated M on a square purple background'
					: 'Purple pixelated M on a square white background'}
			</desc>
			<path
				d="M.013.013V23.826H23.826V.013H.013zm5.292 5.292h5.292v2.646h2.646V5.305h5.291v13.229h-2.646V7.951h-2.645v2.646h-2.646V7.95H7.95V18.534H5.305V5.305z"
				fill={bg}
			/>
			<path
				d="M5.305 13.242H7.95v2.646H5.305v-2.646zm10.583 2.646h2.646v2.646h-2.646v-2.646zm0-2.646h2.646v2.646h-2.646v-2.646zm0-2.645h2.646v2.645h-2.646v-2.645zm0-2.646h2.646v2.646h-2.646V7.95zm0-2.646h2.646V7.95h-2.646V5.305zm-2.646 0h2.646V7.95h-2.646V5.305zM10.597 7.95h2.645v2.646h-2.645V7.95zM7.95 5.305h2.646V7.95H7.95V5.305zm-2.646 0H7.95V7.95H5.305V5.305zm0 2.646H7.95v2.646H5.305V7.95zm0 2.646H7.95v2.645H5.305v-2.645zm0 5.291H7.95v2.646H5.305v-2.646z"
				fill={m}
			/>
		</svg>
	);
}
