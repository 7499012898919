import React, { createContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import useMyGithubRepos from '../hooks/useMyGithubRepos';
export const RepoExtraContext= createContext();



const RepoExtraContextProvider = ({ children }) => {
	const [extraRepos, setExtraRepos] = useState([]);
	const theRepos = useMyGithubRepos().repos();
	const repoNames = useMyGithubRepos().names();

	const data = useStaticQuery(graphql`
	query NonPageQuery {
		allGithubData {
			nodes {
				data {
					one {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeOld {
							text
						}
					}
					two {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeOld {
							text
						}
					}

					three {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeOld {
							text
						}
					}
					four {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeNew {
							text
						}
					}
					five {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeNew {
							text
						}
					}
					six {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeNew {
							text
						}
					}
					seven {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeNew {
							text
						}
					}
					eight {
						description
						name
						url
						languages {
							edges {
								node {
									color
									id
									name
								}
								size
							}
							nodes {
								color
								id
								name
							}
							totalCount
							totalSize
						}
						readmeNew {
							text
						}
					}
				}
			}
		}
	}
`)
	useEffect(()=>{

		const short = data.allGithubData.nodes[0].data;
			// Put short.one, short.two etc into an array for convenience.
			const ans = [];
			for (const key in short) {
				ans.push(short[key]);
		
			}
			const reposWithExtraInfo = ans.map((r) => {
				if (repoNames.includes(r.name)) {
					// Find this repo (r) in the useMyGithubRepos definitions.
					const thisDefin = theRepos.find((rName) => rName.name === r.name);
		
					// Amalgamate info defined in useMyGithubRepos with github's info.
					r.displayName = thisDefin.displayName;
					r.position = thisDefin.position;
					r.displayUrl = thisDefin.displayUrl;
					r.img = thisDefin.imgUrl;
					r.gif = thisDefin.gif;
					r.mp4 = thisDefin.mp4;
					r.webm = thisDefin.webm;
					r.tech = thisDefin.tech;
					r.liveUrl = thisDefin.liveUrl;
					r.localReadme = thisDefin.localReadme;
					r.localDescription = thisDefin.localDescription;
					r.videoDescription = thisDefin.videoDescription;
					r.video = thisDefin.video;
					r.busWidget = thisDefin.busWidget || false;
					r.weatherWidget = thisDefin.weatherWidget || false;
					r.showGitUrl = thisDefin.showGitUrl;
					return r;
				}
				return repoNames.includes(r.name);
			});

			// Sort by position as defined in useMyGithubRepos.
			const sortedRepos = reposWithExtraInfo.sort( (a, b) => {
				return a.position - b.position
			});

			setExtraRepos(sortedRepos);
	},[])
	
	return (
		<RepoExtraContext.Provider value={{ extraRepos, setExtraRepos }}>
			{children}
		</RepoExtraContext.Provider>
	);
};

export default RepoExtraContextProvider;
