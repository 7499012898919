import React, { createContext, useState, useEffect } from 'react';
export const HouseContext = createContext();

function debounce(fn, ms) {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}

const HouseContextProvider = ({ children }) => {
	const [houseWrapFontSize, setHouseWrapFontSize] = useState(null);

	const [isBrowser, setIsBrowser] = useState(false);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsBrowser(true);
		}
	}, []);

	// Init font size on .housesWrap so houses go the width of the screen.
	useEffect(() => {
		// 1256.73 is the max width to see all the houses for a font size of 10px (houses total 158em)
		// so use whateverNewWidth * 10 / 1256.73 to get the same proportions for whateverNewWidth.
		const update = (document.body.clientWidth * 10) / 1256.73;

		setHouseWrapFontSize(update);
	}, []);

	// Resize houses when the window changes.
	useEffect(() => {
		// debounce function for less renders per second.
		const debouncedHandleResize = debounce(function handleResize() {
			const update = (document.body.clientWidth * 10) / 1256.73;

			setHouseWrapFontSize(update);
		}, 100);
		// if( typeof window !== 'undefined') {
		if (isBrowser) {
			window.addEventListener('resize', debouncedHandleResize);
			return (_) => {
				window.removeEventListener('resize', debouncedHandleResize);
			};
		}
	});

	return (
		<HouseContext.Provider value={{ houseWrapFontSize, setHouseWrapFontSize }}>
			{children}
		</HouseContext.Provider>
	);
};

export default HouseContextProvider;
