exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-bus-repo-js": () => import("./../../../src/templates/BusRepo.js" /* webpackChunkName: "component---src-templates-bus-repo-js" */),
  "component---src-templates-repo-js": () => import("./../../../src/templates/Repo.js" /* webpackChunkName: "component---src-templates-repo-js" */),
  "component---src-templates-weather-repo-js": () => import("./../../../src/templates/WeatherRepo.js" /* webpackChunkName: "component---src-templates-weather-repo-js" */)
}

