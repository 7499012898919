import React, { createContext, useState } from 'react';
export const DaylightContext = createContext();

const DaylightContextProvider = ({ children }) => {
	const [daylight, setDaylight] = useState(false);

	return (
		<DaylightContext.Provider value={{ daylight, setDaylight }}>
			{children}
		</DaylightContext.Provider>
	);
};

export default DaylightContextProvider;