import React from 'react';
import * as classes from './daylightSwitch.module.scss';
import Sun from '../svg/sun/Sun';
import Moon from '../svg/moon/Moon';
const DaylightSwitch = ({ daylight, setDaylight }) => {
	return (
		<button
			onClick={() => setDaylight(!daylight)}
			className={`${classes.daylightBtn} button`}
		>
			<span className="srText">
				{daylight ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
			</span>
			{daylight ? <Moon /> : <Sun />}
		</button>
	);
};

export default DaylightSwitch;
