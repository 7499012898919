import React from 'react';
import ExternalLink from '../components/svg/external/ExternalLink';
// ================
// Util functions. TODO
// ================

function getImageUrl(width, title, type) {
	return `https://res.cloudinary.com/djodskjnm/image/upload/c_scale,w_${width}/v1626164086/marie/${title}.${type}`;
}

function getVideoUrl(title, type, v = 'v1626687464') {
	return `https://res.cloudinary.com/djodskjnm/video/upload/${v}/marie/${title}.${type}`;
}

export default function useMyGithubRepos() {
	const imageType = 'webp';

	// Define github repos to use on the site. theRepos[].name needs to correspond to the name on github, everything else can be edited here.
	// Note theRepos[].localReadme is rendered before any github README.md.
	const theRepos = [
		{
			name: 'galway-bus-api',
			position: 1,
			displayName: 'Galway Bus API',
			displayUrl: 'galway-bus.apis.ie',
			imgUrl: getImageUrl(360, 'gbapi_pic', imageType),
			video: false,
			busWidget: true,
			webm: getVideoUrl('gbapi', 'webm'),
			mp4: getVideoUrl('gbapi', 'mp4'),
			videoDescription: 'Screen capture of galway-bus.apis.ie',
			tech: ['node.js', 'Express', 'GTFS', 'MongoDB'],
			liveUrl: 'https://galway-bus.apis.ie',
			showGitUrl: true,
			localReadme: () => (
				<>
					<h2>About</h2>
					<p>
						Until 2020 Transport for Ireland provided bus information (including
						realtime) via the RTPI REST api. The data is still available, now in
						GTFS and GTFS-R (specifications). Consequently the barrier for entry
						is higher and tedious.
					</p>
					<h3 className="h5">Dealing with GTFS involves two things:</h3>
					<ol>
						<li>
							<h4 className="h6">Static information</h4> - routes, timetables,
							bus stops etc. It can be downloaded from{' '}
							<a
								className="inlineLink"
								href="https://www.transportforireland.ie/transitData/PT_Data.html"
							>
								transportforireland.ie
							
							<ExternalLink />
							</a>{' '}
							. Some of the files are BIG and you need to put it into a database
							and write some fairly annoying queries to get anything meaningful.
						</li>
						<li>
							<h4 className="h6">GTFS-R feed</h4> - this is the realtime
							information. You can sign up for a GTFS-R feed key at{' '}
							<a
								className="inlineLink"
								href="https://developer.nationaltransport.ie/api-details#api=gtfsr&operation=gtfsr"
							>
								developer.nationaltransport.ie
								<ExternalLink />
							</a>{' '}
							and read about the specification at gtfs.org. The feed data is
							only useful in conjunction with the static data..
						</li>
					</ol>
					<p>
						Galway Bus API is a REST API that serves all this information for
						Galway City Buses without any GTFS palava.
					</p>
					<h3 className="h4">Read More</h3>
					<a className="inlineLink" href="https://galway-bus.apis.ie">
						galway-bus.apis.ie
						<ExternalLink />
					</a>
					<h3 className="h4">Base Url</h3>
					<a className="inlineLink" href="https://galway-bus.apis.ie/api">
						galway-bus.apis.ie/api
						<ExternalLink />
					</a>
					<h3 className="h4">Website Demo</h3>
					<a className="inlineLink" href="https://busload.ie">
						busload.ie
						<ExternalLink />
					</a>
				</>
			),
		},
		{
			name: 'met-eireann-api',
			position: 2,
			displayName: 'Met Éireann data API',
			displayUrl: 'weather.apis.ie',
			video: false,
			weatherWidget: true,
			webm: getVideoUrl('met_auto', 'webm'),
			mp4: getVideoUrl('met_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio) showing apis.ie homepage. Navigates to docs page. Clicks through some of the documentation (too fast to read anything properly). Copies the GraphQL query example for Met Eireann WeatherForecast API, clicks on Go To API button, pastes in the query to show the API working.',
			imgUrl: getImageUrl(360, 'met_pic', imageType),
			tech: ['nodejs', 'GraphQL', 'Express'],
			liveUrl: 'https://weather.apis.ie',
			showGitUrl: true,
			localDescription: () => (
				<p>
					A GraphQL API providing access to Met Éireann resources from a single
					endpoint.
				</p>
			),
			localReadme: () => (
				<>
					<h2 className="h3">About</h2>
					<p>
						Met Éireann have >2000 entries on data.gov.ie. As well as their main
						Weather Forecast API, Weather Warnings & Live Text Forecast feeds
						there's a load of other information available in varying formats and
						from different endpoints.
					</p>
					<p>
						This project makes it available in one place and in the same format.
					</p>

					<h2 className="h3">Features</h2>
					<ul>
						<li>Met Eireann WeatherForecast API</li>
						<li>Met Eireann Live Text Forecst Data</li>
						<li>Farming Commentary</li>
						<li>Present Observations</li>
						<li>Web 3-day Forecast</li>
						<li>Coastal Reports</li>
						<li>Sea Crossings Forecast</li>
						<li>Inland Lakes Forecast</li>
						<li>Regional Forecast</li>
						<li>National Forecast</li>
						<li>National Outlook</li>
						<li>Weather Warnings</li>
						<li>County Forecast</li>
						<li>Todays's Weather</li>
						<li>Monthly Weather</li>
						<li>Yesterday's Weather</li>
						<li>Agricultural Data Report</li>
						<li>Daily Data</li>
					</ul>
				</>
			),
		},
		{
			name: 'IrelandCoronaStats',
			position: 8,
			displayName: 'Ireland Coronavirus Data Visualisations',
			displayUrl: 'irelandstats.netlify.app',
			video: true,
			imgUrl: getImageUrl(360, 'covid', imageType),
			webm: getVideoUrl('covid_auto', 'webm'),
			mp4: getVideoUrl('covid_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio) showing irelandstats.netlify.app. 3 seconds of scrolling through the home page showing the summary dashboard and some graphs (the video is going too fast to read them properly). Navigates to the counties page, presses the back button and plays a bar chart race showing confirmed covid cases for each county since March 2020. Changes the view to show cases per 100,000 instead. Clicks on the Use Linear Scale button to change the barchart from Log Scale view. Clicks on the Tree Graph button, graph changes into a Tree Graph, clicks on a Show Provinces Button, the counties in the tree graph are divided by province.',
			tech: ['React', 'd3.js'],
			liveUrl: 'https://irelandstats.netlify.app/',
			showGitUrl: true,
			localReadme: null,
		},
		{
			name: 'rain-sweeper-web',
			position: 7,
			displayName: 'Rain Sweeper Website',
			displayUrl: 'rain-sweeper.netlify.app',
			video: true,
			imgUrl: getImageUrl(360, 'rain_web_pic', imageType),
			webm: getVideoUrl('rain_web_auto', 'webm'),
			mp4: getVideoUrl('rain_web_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio) of Rain Sweeper website. 7 seconds of speeded up game play. Shows the game over screen. Then navigates to the about page, then to play.google.com to show the app version of the game.',
			tech: ['Gatsbyjs'],
			liveUrl: 'https://rain-sweeper.netlify.app/',
			showGitUrl: true,
			localDescription: () => (
				<p>
					Browser game based on minesweeper. Use <s>flags</s> umbrellas to avoid{' '}
					<s>bombs</s> rain.
				</p>
			),
			localReadme: () => (
				<>
					<h2 className="h3">About</h2>
					<p>
						The website is built with Gatsby.js. RainSweeper is playable with
						mouse or keyboard. It uses real Met Éireann data taken from Athenry
						weather station via{' '}
						<a className="inlineLink" href="https://weather.apis.ie">
							Irish Weather API
							<ExternalLink />
						</a>{' '}
						. There is also an{' '}
						<a
							className="inlineLink"
							href="https://play.google.com/store/apps/details?id=ie.marie.irish_rain_sweeper"
						>
							Android App version
							<ExternalLink />
						</a>{' '}
						to play the 24 other Irish weather stations.
					</p>
					<h3 className="h4">The Game</h3>
					<p>
						The game is something like Minesweeper except instead of mines there
						are rainy days and instead of flags there are umbrellas.
					</p>
					<p>
						You get three spare umbrellas for every game, these open
						automatically if you choose a rainy day. You can add/remove your own
						umbrellas by right clicking or pressing spacebar. The more rounds
						you clear the more spare umbrellas you can collect!
					</p>
				</>
			),
		},
		{
			name: 'rain-sweeper-app',
			position: 3,
			displayName: 'Rain Sweeper Android App',
			displayUrl: 'play.google.com',
			imgUrl: getImageUrl(360, 'rain_app_pic', imageType),
			video: true,
			webm: getVideoUrl('rain_app_auto', 'webm'),
			mp4: getVideoUrl('rain_app_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio)  showing the RainSweeper app on a phone. 11 seconds of speeded up game play. Taps on Settings. Settings shows a list of the 25 weather stations. Selects a different weather station. Starts a new game. Taps on About. Quick view of the about screen.',
			tech: ['React Native', 'Expo'],
			liveUrl:
				'https://play.google.com/store/apps/details?id=ie.marie.irish_rain_sweeper',
				showGitUrl: true,
			localDescription: () => (
				<p>
					Game based on minesweeper. Use <s>flags</s> umbrellas to avoid{' '}
					<s>bombs</s> rain.
				</p>
			),
			localReadme: () => (
				<>
					<h2 className="h3">About</h2>
					<p>
						RainSweeper is a game something like Minesweeper except instead of
						mines there are rainy days and instead of flags there are umbrellas.
					</p>
					<p>
						The app uses real Met Éireann data taken from the 25 main Irish
						weather stations via{' '}
						<a className="inlineLink" href="https://weather.apis.ie">
							weather.apis.ie
						</a>
						.
					</p>
					<p>
						It is available from{' '}
						<a
							className="inlineLink"
							href="https://play.google.com/store/apps/details?id=ie.marie.irish_rain_sweeper"
						>
							Google Play
							<ExternalLink />
						</a>{' '}
						or you can play a{' '}
						<a className="inlineLink" href="https://rain-sweeper.netlify.app/">
							browser version
							<ExternalLink />
						</a>
						.
					</p>
				</>
			),
		},
		{
			name: 'win95_desktop',
			position: 4,
			displayName: 'Windows 95 in plain JavaScript',
			displayUrl: 'mycomputer.rocks',
			video: true,
			imgUrl: getImageUrl(360, 'windos', imageType),
			webm: getVideoUrl('windos_auto', 'webm'),
			mp4: getVideoUrl('windos_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio)  showing the mycomputer.rocks website. Quick view of the clock/calendar. Opens WordPad. Types "hiya" and saves the file. Opens the start menu and launches Tetris> Plays for about 4 seconds. Launches the calculator.',
			tech: ['webpack'],
			liveUrl: 'https://mycomputer.rocks/',
			showGitUrl: true,
			localDescription: () => (
				<p>
					A web recreation of Windows 95. Includes clock, calculator and retro
					style tetris.
				</p>
			),
			localReadme: null,
		},
		{
			name: 'marie-theme',
			position: 5,
			displayName: "Marie's WordPress Theme",
			displayUrl: 'wordpress.org/themes/marie',
			video: true,
			imgUrl: getImageUrl(360, 'wp_pic', imageType),
			webm: getVideoUrl('wp_auto', 'webm'),
			mp4: getVideoUrl('wp_auto', 'mp4'),
			videoDescription:
				'Screencast (without audio) showing the wordpress theme. Scrolls through the home page. Navigates to the blog page, scrolls throught that, clicks on a post about image alignment and scrolls through.',
			tech: ['WordPress'],
			liveUrl: 'https://wordpress.org/themes/marie/',
			showGitUrl: false, // repo is still private, will 404
			localDescription: () => (
				<p>Lightweight, accessible theme with built in breadcrumbs.</p>
			),
			localReadme: () => (
				<>
					<h2 className="h3">About</h2>
					<p>
						The theme is extremely fast and lightweight - it does not use any JavaScript
						libraries.{' '}
					</p>
					<p>
						It is "accessibility-ready" which means it meets all of the{' '}
						<a
							className="inlineLink"
							href="https://make.wordpress.org/themes/handbook/review/accessibility/required/"
						>
							WordPress Requirements
							<ExternalLink />
						</a>{' '}
						and has breadcrumbs and a burger/mobile menu that appears automatically if
						there are a too many menu items to fit across the page.
					</p>

					<p>Download the <a className="inlineLink" href="https://wordpress.org/plugins/marie-theme-front-page/">Marie Theme Front Page</a> plugin from wordpress.org to add A11y buttons and extra sections to the Front Page.</p>
					<p>Demo available at <a className="inlineLink" href="https://wp.marie.ie">wp.marie.ie</a>.</p>
				</>
			),
		},
		{
			name: 'busload',
			position: 6,
			displayName: 'BusLoad',
			displayUrl: 'busload.ie',
			video: true,
			imgUrl: getImageUrl(360, 'busload_pic', imageType),
			webm: getVideoUrl('busload', 'webm'),
			mp4: getVideoUrl('busload', 'mp4'),
			videoDescription: 'Screen capture of busload.ie',
			tech: ['React'],
			liveUrl: 'https://busload.ie',
			showGitUrl: true,
			localDescription: () => (
				<p>Galway City bus timetables and realtime information.</p>
			),
			localReadme: () => (
				<>
					<h2 className="h3">BusLoad</h2>
					<p>
						React App showing Galway City bus timetables - includes both Bus
						Éireann & City Direct, plus route maps and realtime info.
					</p>
					<p>
						Uses{' '}
						<a className="inlineLink" href="https://galway-bus.apis.ie">
							galway-bus.apis.ie
							<ExternalLink />
						</a>
					</p>
				</>
			),
		},
	];
	return {
		repos: () => theRepos,
		names: () => theRepos.map((repo) => repo.name),
		displayNames: () => theRepos.map((repo) => repo.displayName),
	};
}
