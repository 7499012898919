// Adapted from this repo... https://github.com/koniecznytomek/gatsby-transition-links.

import React, { useCallback, useEffect, useState } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';

const TransitionFade = ({ children, url, color, style }) => {
	const [isBrowser, setIsBrowser] = useState(false);
	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsBrowser(true);
		}
	}, []);

	const exitTransition = {
		length: 0.8,
		zIndex: 2,
		trigger: ({ node }) => {
			exitTransition.exitTrigger(node);
			if (isBrowser) {
				if (node) {
					node.style.top = -window.pageYOffset + 'px';
				}
				window.scrollTo({ top: -window.pageYOffset });
			}
		},
		exitTrigger: useCallback((container) => {
			container.setAttribute(
				'style',
				`animation: fadeOut 0.8s cubic-bezier(0.83, 0, 0.17, 1) forwards;`
			);
		}, []),
	};

	const entryTransition = {
		zIndex: 1,
		trigger: ({ node }) => {
			entryTransition.entryTrigger(node);
		},
		entryTrigger: useCallback((container) => {
			container.setAttribute(
				'style',
				`animation: fadeIn 0.8s cubic-bezier(0.83, 0, 0.17, 1) forwards;`
			);
		}, []),
	};

	return (
		<>
			<TransitionLink
				to={url}
				exit={exitTransition}
				entry={entryTransition}
				style={style}
			>
				{children}
			</TransitionLink>
		</>
	);
};

export default TransitionFade;
