import React, { useContext } from 'react';
import * as classes from './footer.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

const Footer = () => {
	const { daylight } = useContext(DaylightContext);
	return (
		<footer className={daylight ? classes.footer : classes.footerDark}>
			<p>
				&copy; {new Date().getFullYear()} Marie
			</p>
		</footer>
	);
};

export default Footer;
