import React from 'react';

import * as classes from './layout.module.scss';
import 'normalize.css';
import '../../styles/global.scss';
import '../../styles/typography.scss';
import DaylightContextProvider from '../../contexts/daylightContext';
import HouseContextProvider from '../../contexts/houseContext';
import RepoExtraProvider from '../../contexts/repoExtraContext';

import Header from '../header/Header';
import Footer from '../footer/Footer';

function Layout({ children, location }) {


	return (
		<DaylightContextProvider>
			<HouseContextProvider>
				<RepoExtraProvider>
					<div className={classes.layout}>
						<Header />
						<>{children}</>
						<Footer />
					</div>
				</RepoExtraProvider>
			</HouseContextProvider>
		</DaylightContextProvider>
	);
}

export default Layout;
