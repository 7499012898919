import React, { useContext } from 'react';

import * as classes from './header.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

import Logo from '../svg/logo/logo';
import Nav from '../nav/Nav';
import TransitionFade from '../transitions/TransitionFade';
import DaylightSwitch from '../daylightSwitch/DaylightSwitch';

export default function Header() {
	const { daylight, setDaylight } = useContext(DaylightContext);

	return (
		<>
			<header className={classes.header}>
				<a href="#main" className="srText skipLink">
					Skip to Content
				</a>

				<TransitionFade
					url={`/`}
					length={0.5}
					title="Home"
					style={{
						width: '2.8rem',
						height: '2.8rem',
						alignSelf: 'flex-start',
					}}
				>
					<Logo />
					<span className="srText">Home</span>
				</TransitionFade>

				<Nav />
				<DaylightSwitch daylight={daylight} setDaylight={setDaylight} />
			</header>
		</>
	);
}
