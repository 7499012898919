import React, { useContext } from 'react';

import * as classes from './nav.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

import TransitionFade from '../transitions/TransitionFade';

function Nav() {
	const { daylight } = useContext(DaylightContext);

	const color = daylight ? 'var(--darkBlack)' : '#fff';
	const background = daylight ? 'none' : 'var(--nightSky)'
	return (
		<nav className={`${classes.nav} ${daylight ? 'daylight' : 'night'}`}>
			<ul>
				<li>
					<TransitionFade
						url={`/`}
						length={0.5}
						style={{color:color, background: background}}
					>
						Home
					</TransitionFade>
				</li>

				<li>
					<TransitionFade
						url={`/projects/`}
						length={0.5}
						style={{color:color, background: background}}
					>
						Projects
					</TransitionFade>
				</li>
				<li>
					<TransitionFade
						url={`/contact/`}
						length={0.5}
						style={{color:color, background: background}}
					>
						Contact
					</TransitionFade>
				</li>
			</ul>
		</nav>
	);
}

export default Nav;
